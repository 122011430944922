import { Profile } from '@/features/profile/profile.context';
import { useSelection } from '@/features/selection/selection';
import { getTagColor } from '@/features/tags/util';
import { BatchActions } from '@/features/ui/batch-actions/batch-actions.component';
import { FlagToggle } from '@/features/ui/flag-toggle/flag-toggle.component';
import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import { useConfirm } from '@/features/ui/modal/modal.hook';
import {
  getPaginationFromQuery,
  usePagination,
} from '@/features/ui/pagination/pagination';
import { Pagination } from '@/features/ui/pagination/pagination.component';
import { Table } from '@/features/ui/table/table.component';
import { getIssueLabel } from '@/features/utils/format';
import { usePaginatedUrlQuery } from '@/features/utils/url-query';
import { PaginationInput } from '@/graphql/generated/types';
import * as globalStyles from '@/styles/globals.scss';
import axios from 'axios';
import queryString from 'query-string';
import { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import {
  Button,
  Label,
  Segment,
  Statistic,
  TableCell,
} from 'semantic-ui-react';
import { Filter, FilterState } from './filter.component';
import {
  useSoftDeleteTimelogsMutation,
  useTimelogsQuery,
  useToggleTimelogBilledMutation,
  useToggleTimelogFlaggedMutation,
} from './list.generated';

export const List: ComponentType = () => {
  const profile = Profile.use();
  let navigate = useNavigate();
  const { selection } = useSelection();

  const columns = useMemo(() => {
    return [
      'Date',
      'Duration',
      profile?.isAdmin ? 'Amount per hour' : undefined,
      'Issue',
      'Tags',
      'Description',
      'User',
      'Billed',
      'Flagged',
      '',
    ].filter((i) => !!i) as string[];
  }, [profile]);

  const [urlQuery, setUrlQuery] = usePaginatedUrlQuery<FilterState>(
    queryString.parse(location.search),
  );

  const [updateIsBilled] = useToggleTimelogBilledMutation();
  const [updateFlagged] = useToggleTimelogFlaggedMutation();
  const [softDeleteBulk] = useSoftDeleteTimelogsMutation();

  const [modal, { ask }] = useConfirm((id: any) =>
    handleSoftDeleteBulk(selection),
  );

  const { loading, error, data, refetch } = useTimelogsQuery({
    fetchPolicy: 'network-only',
    variables: {
      pagination: getPaginationFromQuery(urlQuery),
      filter: {
        query: urlQuery.query,
        billed: urlQuery.billed,
        customerId: urlQuery.customerId,
        flagged: urlQuery.flagged,
        billable: urlQuery.billable,
        projectId: urlQuery.projectId,
        range: urlQuery.range,
        userId: urlQuery.userId,
        tagIds: urlQuery.tags?.split(',').filter((i) => !!i),
        excludeTagIds: urlQuery.excludeTags?.split(',').filter((i) => !!i),
      },
    },
  });

  const onPaginationChange = useCallback(
    (value: PaginationInput) => {
      setUrlQuery({
        ...urlQuery,
        skip: value.skip?.toString(),
        take: value.take?.toString(),
      });
    },
    [urlQuery],
  );

  const pagination = usePagination(
    data?.timelogs.totalCount || 0,
    {
      skip: Number(urlQuery.skip),
      take: Number(urlQuery.take),
    },
    onPaginationChange,
  );

  const handleSoftDeleteBulk = async (selection: string[]) => {
    await softDeleteBulk({ variables: { ids: selection } });
    refetch();
  };

  const onCreate = useCallback(() => {
    navigate('/timelogs/create');
  }, []);

  const onExport = useCallback(() => {
    axios.defaults.withCredentials = true;
    axios
      .post(
        process.env.REST_URI + '/timelogs/export',
        {
          query: {
            ...urlQuery,
            tagIds: urlQuery.tags
              ? urlQuery.tags.split(',').filter((t) => !!t)
              : [],
            excludeTagIds: urlQuery.excludeTags
              ? urlQuery.excludeTags.split(',').filter((t) => !!t)
              : [],
          },
        },
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(function (response) {
        const blob = response.data;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'export.csv';
        link.click();
      })
      .catch(function (error) {
        console.log(error);
        toast.error(`An error occurred :(`);
      });
  }, [urlQuery]);

  useEffect(() => {
    const persistedQuery = localStorage.getItem('timelogs.query');
    if (persistedQuery) {
      setUrlQuery(JSON.parse(persistedQuery));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('timelogs.query', JSON.stringify(urlQuery));
  }, [urlQuery]);

  if (error) return <p>Error :( {error.message}</p>;

  return (
    <>
      <Frame.TitleBar>
        {modal}
        <Frame.Title>Timelogs</Frame.Title>
        <Frame.Actions>
          <div>
            <Button basic color="black" onClick={onExport}>
              Export CSV
            </Button>
            <Button basic color="green" onClick={onCreate}>
              Create Timelog Entry
            </Button>
          </div>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading}>
        <Filter />
        <Segment>
          <Statistic size="small">
            <Statistic.Value>{data?.timelogs.totalCount || 0}</Statistic.Value>
            <Statistic.Label>Timelogs</Statistic.Label>
          </Statistic>
          <Statistic size="small">
            <Statistic.Value>
              <Formatted.Duration
                value={data?.timelogs.totalDuration}
                unit="h"
              />
            </Statistic.Value>
            <Statistic.Label>Total time spent</Statistic.Label>
          </Statistic>
        </Segment>
        <Pagination {...pagination} />

        <BatchActions
          label="Bulk"
          actions={[
            {
              label: '✍ Bulk edit',
              onClick: () => {
                if (selection.length > 0) {
                  localStorage.setItem('timelogIds', JSON.stringify(selection));
                  navigate(`/timelogs/bulk-edit`);
                }
              },
            },

            {
              label: '🗑️ Bulk delete',

              onClick: () => {
                if (selection.length > 0) {
                  ask(`Are you sure you want to delete the selected items?`);
                }
              },
            },
          ]}
        />
        <Table
          withSelection
          columns={columns}
          items={data?.timelogs.data}
          renderRow={(item) => (
            <>
              <TableCell>
                <Formatted.Date value={item.date} />
              </TableCell>
              <TableCell>
                <Formatted.Duration value={item.duration} unit="h" />
                <br />
                {profile?.isAdmin && (
                  <i>
                    <Formatted.Currency
                      value={
                        item.calculatedAmountPerHour *
                        ((item.duration || 1) / 60)
                      }
                    />
                  </i>
                )}
              </TableCell>
              {profile?.isAdmin && (
                <TableCell>
                  <Formatted.Currency value={item.calculatedAmountPerHour} />
                </TableCell>
              )}
              <TableCell>
                <b>{getIssueLabel(item.issue)}</b>
                {item.issue.project && (
                  <>
                    <br />
                    {item.issue.project?.title}
                    <div className={globalStyles.small}>
                      {item.issue.project?.customer?.label}
                    </div>
                  </>
                )}
                {!item.issue.project && (
                  <Label color="red" horizontal>
                    No project/customer assigned!
                  </Label>
                )}
              </TableCell>
              <TableCell>
                {item.tags.map((t) => (
                  <Label
                    size="tiny"
                    style={{
                      color: '#ffffff',
                      backgroundColor: getTagColor(t) as string,
                    }}
                  >
                    {t.name}
                  </Label>
                ))}
              </TableCell>
              <TableCell>
                {item.description ? item.description : item.issue.title}
              </TableCell>
              <TableCell>
                {item.user?.firstName} {item.user?.lastName}
              </TableCell>

              <TableCell>
                {item.issue.billable && (
                  <Switch
                    onChange={() => {
                      updateIsBilled({
                        variables: { id: item.id },
                      });
                    }}
                    checked={item.billedAt !== null}
                    onColor="#21ba45"
                  />
                )}
              </TableCell>
              <TableCell>
                <FlagToggle
                  value={item.flagged}
                  onChange={() =>
                    updateFlagged({
                      variables: { id: item.id },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <Link to={`/timelogs/${item.id}/edit`}>
                  <Button basic>Details</Button>
                </Link>
              </TableCell>
            </>
          )}
        />
      </Frame.Content>
    </>
  );
};
